.marker-cluster {
    border-radius: 50%;
    height: 40px;
    line-height: 37px;
    text-align: center;
    width: 40px;
}
  
.light {
    background: white;
    border: 2px solid #E57700;
    color: black;
}

.dark {
    background: #424242;
    border: 2px solid #2196f3;
    color: white;
}
